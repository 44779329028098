import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const AdminInterviewNotes = () => {
  const [notes, setNotes] = useState([]);
  const [interview, setInterview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { interviewId } = useParams();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Admin Interview Notes',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchInterviewDetails();
    fetchAllNotes();
  }, [interviewId]);

  const fetchInterviewDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('interviews')
        .select(`
          *,
          user_job:user_jobs (
            job:jobs (title),
            applicant:users (name)
          )
        `)
        .eq('id', interviewId)
        .single();

      if (error) throw error;
      setInterview(data);
    } catch (error) {
      console.error('Error fetching interview details:', error);
    }
  };

  const fetchAllNotes = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('interview_notes')
        .select(`
          *,
          user:users (name)
        `)
        .eq('interview_id', interviewId);

      if (error) throw error;
      setNotes(data);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Interview Notes</h1>
      {interview && (
        <div className="mb-4">
          <p><strong>Job:</strong> {interview.user_job.job.title}</p>
          <p><strong>Applicant:</strong> {interview.user_job.applicant.name}</p>
          <p><strong>Date:</strong> {new Date(interview.interview_time).toLocaleString()}</p>
        </div>
      )}
      {notes.map((note) => (
        <div key={note.id} className="mb-4 p-4 border rounded">
          <p><strong>Author:</strong> {note.user.name}</p>
          <p><strong>Last Updated:</strong> {new Date(note.updated_at).toLocaleString()}</p>
          <div dangerouslySetInnerHTML={{ __html: note.content }} />
        </div>
      ))}
    </div>
  );
};

export default AdminInterviewNotes;