import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import SystemAdminHamburger from './SystemAdminHamburger';

const SystemAdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userMetrics, setUserMetrics] = useState({
    totalUsers: 0,
    usersByType: {},
    growthRate: 0
  });
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(20);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Users',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUsers();
    fetchUserMetrics();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      // Use an explicit join on users.employer and clients.client_id
      let query = supabase
        .from('users')
        .select(`
          *,
          user_types (name),
          clients:employer!inner(client_id,name)  // Use employer as the key for the client relationship
        `, { count: 'exact' });
  
      // Filter for clients only if user_type_id is 3 or 4
      query = query.or('user_type_id.eq.3,user_type_id.eq.4');
  
      if (filter !== 'all') {
        query = query.eq('user_type_id', filter);
      }
  
      if (searchTerm) {
        query = query.or(`name.ilike.%${searchTerm}%,login_email.ilike.%${searchTerm}%`);
      }
  
      const { data, error, count } = await query
        .range((currentPage - 1) * usersPerPage, currentPage * usersPerPage - 1)
        .order('created_at', { ascending: false });
  
      if (error) throw error;
  
      setUsers(data);
      // Update the totalUsers in the userMetrics state
      setUserMetrics(prevMetrics => ({
        ...prevMetrics,
        totalUsers: count
      }));
  
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error.message);
      setIsLoading(false);
    }
  };

  const fetchUserMetrics = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('user_type_id, created_at');
  
      if (error) throw error;
  
      const usersByType = data.reduce((acc, user) => {
        acc[user.user_type_id] = (acc[user.user_type_id] || 0) + 1;
        return acc;
      }, {});
  
      const totalUsers = data.length;
      const lastMonthUsers = data.filter(user => 
        new Date(user.created_at) > new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      ).length;
  
      const growthRate = ((lastMonthUsers / totalUsers) * 100).toFixed(2);
  
      setUserMetrics({
        totalUsers,
        usersByType,
        growthRate
      });
    } catch (error) {
      console.error('Error fetching user metrics:', error.message);
    }
  };

  // Implement handleFilterChange, handleSearch, and handlePageChange functions here

  return (
    <>
      <Helmet>
        <title>System Admin Users | fetchConsultant</title>
        <meta name="description" content="Manage and monitor user data on fetchConsultant platform." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <SystemAdminHamburger currentPage="users" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">System Admin Users</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {/* Implement user metrics cards, filtering, search, and user table here */}
          </main>
        </div>
      </div>
    </>
  );
};

export default SystemAdminUsers;