import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, ChevronRight, Plus, Trash2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import ConsultantHamburger from "./ConsultantHamburger";

const ConsultantTimesheet = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [weekStart, setWeekStart] = useState(getWeekStart(new Date()));
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Timesheet',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchJobs();
    checkMobile();

    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (user && selectedJob) {
      fetchTimesheetEntries();
    }
  }, [user, selectedJob, weekStart]);

  function checkMobile() {
    setIsMobile(window.innerWidth <= 768);
  }

  function getWeekStart(date) {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchJobs = async () => {
    try {
      if (!user) return;
      const { data, error } = await supabase
        .from('user_jobs')
        .select('job_id, jobs(title)')
        .eq('user_id', user.id)
        .eq('status', 'Contract Accepted');

      if (error) throw error;
      setJobs(data);
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
    }
  };

  const fetchTimesheetEntries = async () => {
    try {
      if (!user || !selectedJob) return;
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      const { data, error } = await supabase
        .from('timesheet_entries')
        .select('*')
        .eq('consultant_id', user.id)
        .eq('job_id', selectedJob)
        .gte('date', weekStart.toISOString().split('T')[0])
        .lte('date', weekEnd.toISOString().split('T')[0])
        .order('date', { ascending: true });

      if (error) throw error;

      const groupedEntries = data.reduce((acc, entry) => {
        const existingEntry = acc.find(e => e.title === entry.title);
        if (existingEntry) {
          existingEntry[getDayKey(new Date(entry.date))] = entry.hours;
          existingEntry.id = entry.id;
        } else {
          const newEntry = { title: entry.title, id: entry.id };
          newEntry[getDayKey(new Date(entry.date))] = entry.hours;
          acc.push(newEntry);
        }
        return acc;
      }, []);

      setTimesheetEntries(groupedEntries);
    } catch (error) {
      console.error('Error fetching timesheet entries:', error.message);
    }
  };

  const getDayKey = (date) => {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    return days[date.getDay()];
  };

  const handleEntryChange = async (index, field, value) => {
    const newEntries = [...timesheetEntries];
    newEntries[index][field] = value;
    setTimesheetEntries(newEntries);

    if (field !== 'title') {
      const entry = newEntries[index];
      const date = new Date(weekStart);
      date.setDate(date.getDate() + ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(field));

      try {
        const { error } = await supabase
          .from('timesheet_entries')
          .upsert({
            id: entry.id,
            consultant_id: user.id,
            job_id: selectedJob,
            title: entry.title,
            date: date.toISOString().split('T')[0],
            hours: parseFloat(value) || 0,
            status: 'saved'
          }, { onConflict: ['consultant_id', 'job_id', 'date', 'title'] });

        if (error) throw error;
      } catch (error) {
        console.error('Error updating timesheet entry:', error.message);
      }
    }
  };

  const addNewEntry = () => {
    setTimesheetEntries([...timesheetEntries, { title: '', sun: '', mon: '', tue: '', wed: '', thu: '', fri: '', sat: '' }]);
  };

  const removeEntry = async (index) => {
    const entryToRemove = timesheetEntries[index];
    const newEntries = timesheetEntries.filter((_, i) => i !== index);
    setTimesheetEntries(newEntries);

    if (entryToRemove.id) {
      try {
        const { error } = await supabase
          .from('timesheet_entries')
          .delete()
          .eq('id', entryToRemove.id);

        if (error) throw error;
      } catch (error) {
        console.error('Error removing timesheet entry:', error.message);
      }
    }
  };

  const calculateRowTotal = (entry) => {
    return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].reduce((total, day) => {
      return total + (parseFloat(entry[day]) || 0);
    }, 0);
  };

  const calculateColumnTotal = (day) => {
    return timesheetEntries.reduce((total, entry) => {
      return total + (parseFloat(entry[day]) || 0);
    }, 0);
  };

  const calculateGrandTotal = () => {
    return timesheetEntries.reduce((total, entry) => {
      return total + calculateRowTotal(entry);
    }, 0);
  };

  const handleSubmit = async () => {
    try {
      if (!user || !selectedJob) return;

      const updates = timesheetEntries.flatMap(entry => 
        ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].map((day, index) => {
          const date = new Date(weekStart);
          date.setDate(date.getDate() + index);
          return {
            consultant_id: user.id,
            job_id: selectedJob,
            title: entry.title,
            date: date.toISOString().split('T')[0],
            hours: parseFloat(entry[day]) || 0,
            status: 'submitted'
          };
        })
      );

      const { error } = await supabase
        .from('timesheet_entries')
        .upsert(updates, { onConflict: ['consultant_id', 'job_id', 'date', 'title'] });

      if (error) throw error;
      alert('Timesheet submitted successfully');
      fetchTimesheetEntries();
    } catch (error) {
      console.error('Error submitting timesheet:', error.message);
      alert('Error submitting timesheet. Please try again.');
    }
  };

  const changeWeek = (direction) => {
    const newWeekStart = new Date(weekStart);
    newWeekStart.setDate(newWeekStart.getDate() + (direction === 'next' ? 7 : -7));
    setWeekStart(newWeekStart);
  };

  const formatDate = (date) => {
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
  };

  return (
    <>
      <Helmet>
        <title>Consultant Timesheet | fetchConsultant</title>
        <meta name="description" content="Submit and view your weekly timesheet entries as a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <ConsultantHamburger user={user} currentPage="timesheet" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Weekly Timesheet</h1>
          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            <div className="flex justify-between items-center mb-4">
              <button onClick={() => changeWeek('prev')} className="fetch-button"><ChevronLeft /></button>
              <h2 className="text-xl font-bold">Week of {weekStart.toLocaleDateString()}</h2>
              <button onClick={() => changeWeek('next')} className="fetch-button"><ChevronRight /></button>
            </div>
            <div className="mb-4">
              <label htmlFor="job" className="fetch-label">Select Job</label>
              <select
                id="job"
                value={selectedJob}
                onChange={(e) => setSelectedJob(e.target.value)}
                required
                className="fetch-select"
              >
                <option value="">Select a job</option>
                {jobs.map((job) => (
                  <option key={job.job_id} value={job.job_id}>
                    {job.jobs.title}
                  </option>
                ))}
              </select>
            </div>
            {selectedJob && (
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">Title</th>
                      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => {
                        const date = new Date(weekStart);
                        date.setDate(date.getDate() + index);
                        return (
                          <th key={day} className="text-center">
                            <div>{day}</div>
                            <div className="text-xs">{formatDate(date)}</div>
                          </th>
                        );
                      })}
                      <th className="text-center">Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {timesheetEntries.map((entry, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="text"
                            value={entry.title}
                            onChange={(e) => handleEntryChange(index, 'title', e.target.value)}
                            className="fetch-input"
                            placeholder="Enter task title"
                          />
                        </td>
                        {['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].map((day) => (
                          <td key={day}>
                            <input
                              type="number"
                              value={entry[day]}
                              onChange={(e) => handleEntryChange(index, day, e.target.value)}
                              className="fetch-input text-center w-16"
                              step="0.25"
                              min="0"
                              max="24"
                            />
                          </td>
                        ))}
                        <td className="text-center font-bold">{calculateRowTotal(entry).toFixed(2)}</td>
                        <td>
                          <button onClick={() => removeEntry(index)} className="text-red-500">
                            <Trash2 size={20} />
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="8" className="text-right font-bold">Total:</td>
                      {['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].map((day) => (
                        <td key={day} className="text-center font-bold">{calculateColumnTotal(day).toFixed(2)}</td>
                      ))}
                      <td className="text-center font-bold">{calculateGrandTotal().toFixed(2)}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div className="mt-4 flex justify-between">
              <button onClick={addNewEntry} className="fetch-button bg-blue-500 hover:bg-blue-600">
                <Plus size={20} className="mr-2" />
                Add Row
              </button>
              <button onClick={handleSubmit} className="fetch-button bg-green-500 hover:bg-green-600">
                Submit Timesheet
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ConsultantTimesheet;