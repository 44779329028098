import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';

const ConsultantOnboardingProfessional = () => {
  const [formData, setFormData] = useState({
    linkedin_url: '',
    former_epic: false,
    epic_certifications: [],
    current_project_end_date: null,
  });
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [epicApplications, setEpicApplications] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [completionMessage, setCompletionMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Professional' : 'Consultant Onboarding - Professional',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchEpicApplications();
    if (isEditMode) {
      fetchUserData();
    }
  }, [isEditMode]);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select(`
          linkedin_url,
          former_epic,
          current_project_end_date,
          resume,
          user_epic_certifications (epic_application_id)
        `)
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setFormData({
        linkedin_url: data.linkedin_url || 'https://www.linkedin.com/in/',
        former_epic: data.former_epic || false,
        current_project_end_date: data.current_project_end_date || '',
        epic_certifications: data.user_epic_certifications.map(cert => cert.epic_application_id)
      });
      setResume(data.resume || null);
      setResumeName(data.resume ? data.resume.split('/').pop() : '');
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

    const fetchEpicApplications = async () => {
      try {
        const { data, error } = await supabase
          .from('epic_applications')
          .select('epic_application_id, name');
        
        if (error) {
          console.error('Supabase error fetching Epic applications:', error);
        } else if (data) {
          console.log('Fetched Epic applications:', data);
          setEpicApplications(data);
        } else {
          console.log('No Epic applications data received');
        }
      } catch (err) {
        console.error('Unexpected error fetching Epic applications:', err);
      }
    };

  useEffect(() => {
    const isFormValid = (formData.linkedin_url || '').trim() !== '';
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    let newValue = value;
  
    // Handle linkedin_url to avoid duplications
    if (name === "linkedin_url" && value.includes("https://www.linkedin.com/in/")) {
      newValue = value.replace("https://www.linkedin.com/in/", "");
    }
  
    // Handle empty date input and set to null
    if (type === "date" && value === "") {
      newValue = null;  // Set to null if date input is empty
    }
  
    setFormData(prevData => ({
      ...prevData,
      [name]: name === "linkedin_url" ? `https://www.linkedin.com/in/${newValue}` : newValue
    }));
  };

  const handleResumeUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('Please upload a PDF file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert('File size should not exceed 5MB');
        return;
      }
      setResume(file);
    }
  };

  const handleEpicCertificationChange = (certId) => {
    setFormData(prevData => ({
      ...prevData,
      epic_certifications: prevData.epic_certifications.includes(certId)
        ? prevData.epic_certifications.filter(id => id !== certId)
        : [...prevData.epic_certifications, certId]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValid) return;
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      let resumeUrl = resume;
      if (resume instanceof File) {
        const fileExt = resume.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;
  
        const { data, error } = await supabase.storage
          .from('resumes')
          .upload(filePath, resume);
  
        if (error) throw error;
  
        const { data: { publicUrl }, error: urlError } = supabase.storage
          .from('resumes')
          .getPublicUrl(filePath);
  
        if (urlError) throw urlError;
        resumeUrl = publicUrl;
      }

      const currentProjectEndDate = formData.current_project_end_date || null;
  
      const { error } = await supabase
        .from('users')
        .update({
          linkedin_url: formData.linkedin_url,
          former_epic: formData.former_epic,
          resume: resumeUrl,
          current_project_end_date: currentProjectEndDate, 
        })
        .eq('user_id', user.id);
  
      if (error) throw error;
  
      // Update epic certifications
      await supabase
        .from('user_epic_certifications')
        .delete()
        .eq('user_id', user.id);
  
      if (formData.epic_certifications.length > 0) {
        await supabase
          .from('user_epic_certifications')
          .insert(formData.epic_certifications.map(certId => ({
            user_id: user.id,
            epic_application_id: certId
          })));
      }
  
      if (!isEditMode) {
        // Set default notification settings
        await supabase.from('notification_settings').insert([
          {
            user_id: user.id,
            notification_type: 'all_epic_certifications',
            notification_options: { email: true, push: true, text: true }
          },
          {
            user_id: user.id,
            notification_type: 'user_specific',
            notification_options: { email: true, push: true, text: true }
          }
        ]);
  
        // Request notification permission
        await requestNotificationPermission();
      }
  
      // Navigate based on isEditMode value
      if (isEditMode) {
        navigate('/app/consultant-dashboard');
      } else {
        navigate('/app/welcome-consultant');
      }
  
    } catch (error) {
      console.error('Error updating user info:', error.message);
      alert(`Error updating profile: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
      <title>{isEditMode ? 'Edit Profile - Professional Information' : 'Consultant Onboarding - Professional Information'} | fetchConsultant</title>
      <meta name="description" content={isEditMode ? "Update your professional profile as a healthcare IT consultant on fetchConsultant." : "Complete your professional profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities."} />
      <meta name="description" content="Complete your professional profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Consultant Onboarding - Professional Information | fetchConsultant" />
      <meta property="og:description" content="Complete your professional profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://fetchconsultant.com/app/consultant-onboarding-professional" />
      <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
    </Helmet>
      {/* Split screen */}
      <div className="flex w-full max-w-7xl shadow-lg">
  
        {/* Left section */}
        <div className="bg-white p-4 sm:p-8 w-full sm:w-1/2 flex flex-col justify-start sm:justify-center items-center min-h-screen sm:min-h-0 overflow-y-auto">
        <header className="fetch-header w-full">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">
            {isEditMode ? 'Edit Profile' : 'Consultant Onboarding'}</h1>
        </header>
        <main className="fetch-container w-full">
          <div className="fetch-card">
            {!isEditMode && (
              <div className="mb-6 text-center">
                <p className="fetch-secondary-text">Step 2 of 2</p>
                <div className="fetch-progress-bar">
                  <div className="fetch-progress-bar-fill" style={{ width: '100%' }}></div>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit} className="fetch-form">
                    <div>
                      <label htmlFor="linkedin_url" className="fetch-label">LinkedIn URL<span className="fetch-required">*</span></label>
                      <input
                        type="url"
                        id="linkedin_url"
                        name="linkedin_url"
                        value={formData.linkedin_url}
                        onChange={handleInputChange}
                        required
                        className="fetch-input"
                        placeholder="shanesadams"
                      />
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="former_epic"
                        name="former_epic"
                        checked={formData.former_epic}
                        onChange={handleInputChange}
                        className="fetch-checkbox"
                      />
                      <label htmlFor="former_epic" className="fetch-checkbox-label">
                        Former Epic Employee
                      </label>
                    </div>
                    <div>
                <label htmlFor="resume" className="fetch-label">
                  {resumeName ? 'Update Resume (PDF only)' : 'Upload Resume (PDF only)'}
                </label>
                <div className="mt-1 flex flex-col items-start">
                  <input
                    type="file"
                    id="resume"
                    accept=".pdf"
                    onChange={handleResumeUpload}
                    className="hidden"
                  />
                  <label
                    htmlFor="resume"
                    className="file-input-button"
                  >
                    <Upload className="w-5 h-5 mr-2" />
                    {resumeName ? 'Update Resume' : 'Upload Resume'}
                  </label>
                  {(resume instanceof File ? resume.name : resumeName) && (
                    <span className="text-sm text-gray-600 mt-2">
                      {resume instanceof File ? resume.name : resumeName}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label className="fetch-label mb-2">Epic Certifications</label>
                <div className="fetch-card text-left">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {epicApplications.map((app) => (
                      <div key={app.epic_application_id} className="flex items-start">
                        <input
                          type="checkbox"
                          id={`cert-${app.epic_application_id}`}
                          name="epic_certifications"
                          value={app.epic_application_id}
                          checked={formData.epic_certifications.includes(app.epic_application_id)}
                          onChange={() => handleEpicCertificationChange(app.epic_application_id)}
                          className="fetch-checkbox mt-1"
                        />
                        <label htmlFor={`cert-${app.epic_application_id}`} className="fetch-checkbox-label ml-2 text-sm break-words">
                          {app.name}
                        </label>
                      </div> 
                    ))}
                  </div>
                </div>
              </div>
                    <div>
                      <label htmlFor="current_project_end_date" className="fetch-label">Current Project End Date</label>
                      <input
                        type="date"
                        id="current_project_end_date"
                        name="current_project_end_date"
                        value={formData.current_project_end_date}
                        onChange={handleInputChange}
                        className="fetch-input"
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!formValid}
                      >
                        Finish
                      </button>
                    </div>
                  </form>
                </div>
              </main>
            </div>
            {/* Right section */}
            <div className="hidden sm:block bg-brandPrimary text-white p-10 w-1/2 flex flex-col justify-between overflow-y-auto">
              <div className="flex flex-col justify-center flex-grow">
                <h2 className="text-center text-3xl font-extrabold mt-3 mb-8">Why fetchConsultant</h2>
                <div className="flex justify-center">
                  <ul className="space-y-6 flex flex-col">
                      <li>
                        <div className="flex items-start">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">Maximize Your Earnings</h3>
                        </div>
                        <p className="text-left ml-10">Keep more of what you earn with our flat 9% fee and 1099 status for enhanced 401(k) savings.</p>
                      </li>
                      <li>
                        <div className="flex items-start mt-2">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">Instant Job Alerts</h3>
                        </div>
                        <p className="text-left ml-10">Receive real-time notifications for jobs matching your skills and preferences.</p>
                      </li>
                      <li>
                        <div className="flex items-start mt-2">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">Fair Compensation</h3>
                        </div>
                        <p className="text-left ml-10">One transparent rate between you and the client. No more lowball offers from middlemen.</p>
                      </li>
                      <li>
                        <div className="flex items-start mt-2">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">Seamless Interview Process</h3>
                        </div>
                        <p className="text-left ml-10">Integrated Zoom meetings and scheduling tools connect you with potential clients quickly.</p>
                      </li>
                    </ul>
                  </div>
                </div>
                {completionMessage && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">{completionMessage.title}</h3>
                      <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500">{completionMessage.body}</p>
                        {completionMessage.warning && (
                          <p className="text-sm text-yellow-600 mt-2">{completionMessage.warning}</p>
                        )}
                      </div>
                      <div className="items-center px-4 py-3">
                        {completionMessage.showSettings && (
                          <button
                            onClick={() => navigate('/app/consultant-settings')}
                            className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                          >
                            Go to Settings
                          </button>
                        )}
                        <button
                          onClick={() => navigate('/app/consultant-dashboard')}
                          className="mt-3 px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                        >
                          Go to Dashboard
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      };

      export default ConsultantOnboardingProfessional;