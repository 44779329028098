import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu, MoreHorizontal, Star } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';

const AllApplicants = () => {
  const [applicants, setApplicants] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'submitted_at', direction: 'desc' });
  const [statusFilter, setStatusFilter] = useState('All');
  const [jobFilter, setJobFilter] = useState('All');
  const [isMobile, setIsMobile] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'All Applicants',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchJobs();
    checkMobile();

    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (user) {
      fetchApplicants();
    }
  }, [user, statusFilter, jobFilter, sortConfig]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const fetchUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();
      if (error) console.error('Error fetching user:', error);
      else setUser(data);
    }
  };

  const fetchJobs = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('jobs')
        .select('job_id, title')
        .eq('created_by', user.id);
      if (error) console.error('Error fetching jobs:', error);
      else setJobs(data);
    }
  };

  const fetchApplicants = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      let query = supabase
        .from('user_jobs')
        .select(`
          user_job_id,
          submitted_at,
          consultant_rate,
          status,
          fetch_recommended,
          job_id,
          users:user_id (
            name,
            photo,
            linkedin_url,
            average_rating,
            former_epic
          ),
          jobs:job_id (
            title
          )
        `)
        .eq('jobs.created_by', user.id);

      if (statusFilter !== 'All') {
        query = query.eq('status', statusFilter);
      }

      if (jobFilter !== 'All') {
        query = query.eq('job_id', jobFilter);
      }

      const { data, error } = await query;

      if (error) console.error('Error fetching applicants:', error);
      else {
        const sortedData = data.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
          if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        });
        setApplicants(sortedData);
      }
    }
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const toggleDropdown = (userJobId) => {
    setActiveDropdown(activeDropdown === userJobId ? null : userJobId);
  };

  const handleApplicantClick = (userJobId) => {
    navigate(`/app/applicant/${userJobId}`);
  };

  const handleCreateNote = (applicant, e) => {
    e.stopPropagation();  // Stop the event from bubbling up
    navigate('/app/create-interview-note', { 
      state: { 
        consultantId: applicant.users.user_id,
        consultantName: applicant.users.name
      } 
    });
  };

  const renderStars = (rating) => {
    if (!rating) {
      return <span>No ratings yet</span>;
    }
  
    const stars = [];
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
    for (let i = 1; i <= 5; i++) {
      let starFill = Math.min(Math.max(roundedRating - (i - 1), 0), 1);
      if (starFill === 1) {
        // Full star
        stars.push(<Star key={i} size={20} fill="gold" stroke="gold" />);
      } else if (starFill > 0) {
        // Partial star
        stars.push(
          <div key={i} className="relative">
            <Star size={20} fill="none" stroke="gold" />
            <div
              className="absolute top-0 left-0 overflow-hidden"
              style={{ width: `${starFill * 100}%` }}
            >
              <Star size={20} fill="gold" stroke="gold" />
            </div>
          </div>
        );
      } else {
        // Empty star
        stars.push(<Star key={i} size={20} fill="none" stroke="gold" />);
      }
    }
    return stars;
  };

  const renderApplicantsTable = () => (
    <div className="bg-white shadow-md rounded-lg">
      <table className="min-w-full table-auto divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('name')} className="cursor-pointer">
                Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('submitted_at')} className="cursor-pointer">
                Applied {sortConfig.key === 'submitted_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('jobs.title')} className="cursor-pointer">
                Job Title {sortConfig.key === 'jobs.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('consultant_rate')} className="cursor-pointer">
                Rate {sortConfig.key === 'consultant_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('status')} className="cursor-pointer">
                Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('users.average_rating')} className="cursor-pointer">
                Rating {sortConfig.key === 'users.average_rating' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {applicants.map((applicant) => (
            <tr key={applicant.user_job_id} onClick={() => handleApplicantClick(applicant.user_job_id)} className="hover:bg-gray-50 cursor-pointer">
              <td className="px-6 py-4 whitespace-nowrap text-left">
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-10 w-10">
                    <img className="h-10 w-10 rounded-full" src={applicant.users.photo || '/default-avatar.png'} alt="" />
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">{applicant.users.name}</div>
                    {applicant.users.former_epic && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Ex-Epic</span>}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">
                {new Date(applicant.submitted_at).toLocaleDateString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">
                {applicant.jobs.title}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">
                ${applicant.consultant_rate}/hr
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {applicant.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">
                <div className="flex">{renderStars(applicant.users.average_rating)}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium relative">
                <MoreHorizontal
                  size={20}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(applicant.user_job_id);
                  }}
                />
                {activeDropdown === applicant.user_job_id && (
                  <div ref={dropdownRef} className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                     <a 
                        href={applicant.users.linkedin_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        LinkedIn Profile
                      </a>
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={(e) => handleCreateNote(applicant, e)}
                        >
                        📝 Create Note
                      </div>                    
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderApplicantsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {applicants.map((applicant) => (
        <div 
          key={applicant.user_job_id} 
          className="relative bg-white text-left shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow cursor-pointer"
          onClick={() => handleApplicantClick(applicant.user_job_id)}
        >
          {/* Ex-Epic and Recommended Labels */}
          <div className="absolute top-0 left-0 flex space-x-2">
            {applicant.users.former_epic && (
              <div className="bg-indigo-800 text-white text-xs font-semibold px-3 py-1 rounded-tr-lg rounded-bl-lg">
                Ex-Epic
              </div>
            )}
            {/* Use fetch_recommended directly from applicant */}
            {applicant.fetch_recommended && (
              <div className="bg-green-700 text-white text-xs font-semibold px-3 py-1 rounded-tr-lg rounded-bl-lg">
                Recommended
              </div>
            )}
          </div>
  
          {/* Applicant Name and Job Title */}
          <div className="mt-2 mb-2">
            <h2 className="text-xl font-bold text-gray-900">{applicant.users.name}</h2>
            <p className="text-sm text-gray-500">{applicant.jobs.title}</p>
          </div>
  
          {/* Applicant Details */}
          <div className="text-sm text-gray-500 mb-2">
            <p>Applied: {new Date(applicant.submitted_at).toLocaleDateString()}</p>
            <p>Rate: ${applicant.consultant_rate}/hr</p>
            <p>Status: {applicant.status}</p>
            <div className="flex items-center">
              <p className="mr-2">Rating:</p>
              <div className="flex">{renderStars(applicant.users.average_rating)}</div>
            </div>
          </div>

          {/* MoreHorizontal Menu */}
          <div className="absolute bottom-4 right-4">
            <MoreHorizontal 
              size={24} 
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click
                toggleDropdown(applicant.user_job_id); // Toggle dropdown
              }}
            />
            {activeDropdown === applicant.user_job_id && (
              <div
                className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
              >
                <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  <a href={applicant.users.linkedin_url} target="_blank" rel="noopener noreferrer">
                    LinkedIn Profile
                  </a>
                </div>
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={(e) => handleCreateNote(applicant, e)}
                  >
                  📝 Create Note
                </div> 
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>All Applicants | fetchConsultant</title>
        <meta name="description" content="View and manage applicants for your job postings on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="all applicants" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex bg-gray-100 flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate('/app/client-dashboard')} />
              <h1 className="text-xl font-bold ml-4">Applicants</h1>
            </div>
            <NotificationBell />
          </header>

          <main className="p-4">
            <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 sm:mb-0">
                <div className="mr-4 mb-2 sm:mb-0">
                  <select
                    id="statusFilter"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className="fetch-select"
                  >
                    <option value="All">All Statuses</option>
                    <option value="Applied">Applied</option>
                    <option value="Interview Scheduled">Interview Scheduled</option>
                    <option value="Contract Offered">Contract Offered</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
                <div>
                  <select
                    id="jobFilter"
                    value={jobFilter}
                    onChange={(e) => setJobFilter(e.target.value)}
                    className="fetch-select"
                  >
                    <option value="All">All Jobs</option>
                    {jobs.map((job) => (
                      <option key={job.job_id} value={job.job_id}>{job.title}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {applicants.length === 0 ? (
              <p className="text-gray-600 text-center">No applicants found.</p>
            ) : (
              isMobile ? renderApplicantsCards() : renderApplicantsTable()
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default AllApplicants;