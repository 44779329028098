import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientOrConsultant = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'User Type Selection',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const checkSession = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();
        if (error) throw error;
        if (!data.session) {
          console.log("No active session found");
          navigate('/app/auth');
          return;
        }
        
        const { user } = data.session;
        if (!user.email_confirmed_at) {
          console.log("Email not verified");
          navigate('/app/verify-email');
          return;
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error checking session:', error.message);
        navigate('/app/auth');
      }
    };

    checkSession();
  }, [navigate]);

  const handleUserTypeSelection = async (userType) => {
    try {
      const { data, error } = await supabase.auth.getUser();
      
      if (error || !data.user) {
        console.error("No user found");
        navigate('/app/auth');
        return;
      }

      const { error: updateError } = await supabase
        .from('users')
        .update({ user_type_id: userType === 'client' ? 3 : 2 }) // Assuming 3 is for Unverified Client and 2 is for Consultant
        .eq('user_id', data.user.id);

      if (updateError) throw updateError;

      navigate(userType === 'client' ? '/app/client-onboarding-personal' : '/app/consultant-onboarding-personal');
    } catch (error) {
      console.error('Error updating user type:', error.message);
      // Handle error (show error message to user)
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  return (
    <div className="min-h-screen bg-gray-100 sm:flex sm:justify-center sm:items-center">
      <Helmet>
        <title>Choose Your Role | fetchConsultant</title>
        <meta name="description" content="Are you a healthcare IT consultant or a healthcare organization looking for Epic talent? Choose your role to get started with fetchConsultant." />
      </Helmet>
  
      {/* Split screen */}
      <div className="flex w-full max-w-4xl shadow-lg">
  
        {/* Left section */}
        <div className="bg-white p-8 w-full sm:max-w-md flex flex-col justify-center items-center">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              How Can We Help?
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Are you a Consultant or a Client?
            </p>
          </div>
  
          <div className="mt-8 w-full sm:w-auto flex flex-col space-y-6 items-center">
            <button
              onClick={() => handleUserTypeSelection('client')}
              className="w-full sm:w-72 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brandPrimary hover:bg-brandHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Post jobs as a Client
            </button>
  
            <button
              onClick={() => handleUserTypeSelection('consultant')}
              className="w-full sm:w-72 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brandPrimary hover:bg-brandHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Find jobs as a Consultant
            </button>
          </div>
        </div>
        {/* Right section */}
        <div className="hidden md:block bg-brandPrimary text-white p-10 w-1/2 flex flex-col justify-between">
          <div className="flex flex-col justify-center flex-grow">
            <h2 className="text-center text-3xl font-extrabold mb-8">Why fetchConsultant</h2>
              <div className="flex justify-center"> {/* Container to center vertically */}
                <ul className="space-y-6 flex flex-col">
                  <li>
                    <div className="flex items-start">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Transparent, Fair Pricing</h3>
                    </div>
                    <p className="text-left ml-10">One rate with a simple 9% platform fee. No hidden costs, no inflated rates. Fair for everyone.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Real-Time Opportunities</h3>
                    </div>
                    <p className="text-left ml-10">Instant notifications for new jobs and applicants. Fill positions in days, not weeks.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Streamlined Contracting Process</h3>
                    </div>
                    <p className="text-left ml-10">From job posting to Zoom interviews, our integrated platform accelerates every step of hiring.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default ClientOrConsultant;