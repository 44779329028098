import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, MoreHorizontal, Pause, Trash2 } from 'lucide-react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import { UserContext } from '../UserContext';  // Import UserContext to access the user data

const EmptyState = () => (
  <div className="bg-white rounded-lg shadow-md p-8 text-left">
    <h2 className="text-2xl font-bold text-brandPrimary mb-4">Welcome to fetchConsultant!</h2>
    <p className="text-lg mb-6">Start posting jobs and experience the benefits:</p>
    <ul className="text-left list-disc list-inside mb-6">
      <li className="mb-2">Instantly broadcast contracts to hundreds of qualified consultants</li>
      <li className="mb-2">Require video responses for quick filtering of candidates</li>
      <li className="mb-2">Streamlined interviewing with scheduling and Zoom integration</li>
      <li className="mb-2">Save money by going directly to consultants</li>
      <li className="mb-2">Retain the assurance of having the contract with fetchConsultant</li>
    </ul>
    <p className="text-lg font-semibold">Click "Post Job" to get started!</p>
  </div>
);

const ClientDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const { user } = useContext(UserContext);  // Access user data from context
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // To handle the MoreHorizontal menu
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'posted_at', direction: 'desc' });
  const [statusFilter, setStatusFilter] = useState('All');

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobs();
    checkMobile();
  }, [statusFilter, sortConfig]);

  // 2. Existing `useEffect` for handling click outside of dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null); // Close dropdown if clicked outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  // 1. New `useEffect` for detecting mobile or desktop screen size
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this width as necessary
    };

  const fetchJobs = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      let query = supabase
        .from('jobs')
        .select(`
          *,
          user_jobs (count)
        `)
        .eq('created_by', user.id)

      if (sortConfig) {
        query = query.order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });
      }

      if (statusFilter !== 'All') {
        query = query.eq('status', statusFilter);
      }
  
      const { data, error } = await query;
  
      if (error) throw error;
      setJobs(data.map(job => ({
        ...job,
        applicant_count: job.user_jobs[0]?.count || 0
      })));
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (jobId) => {
    setActiveDropdown(activeDropdown === jobId ? null : jobId); // Toggle dropdown visibility
  };

  const handleEditJob = (e, jobId) => {
    e.stopPropagation();
    navigate(`/app/edit-job/${jobId}`);
  };

  const handlePauseJob = async (e, jobId) => {
    e.stopPropagation();
    try {
      const { error } = await supabase
        .from('jobs')
        .update({ status: 'Paused' })
        .eq('job_id', jobId);
  
      if (error) throw error;
      fetchJobs(); // Refresh the job list
    } catch (error) {
      console.error('Error pausing job:', error);
    }
  };
  
  const handleDeleteJob = async (e, jobId) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this job? This action cannot be undone.')) {
      try {
        const { error } = await supabase
          .from('jobs')
          .update({ status: 'Deleted' })
          .eq('job_id', jobId);
  
        if (error) throw error;
        fetchJobs(); // Refresh the job list
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }
  };

  const handleActivateJob = async (e, jobId) => {
    e.stopPropagation();
    try {
      const { error } = await supabase
        .from('jobs')
        .update({ status: 'Active' })
        .eq('job_id', jobId);
  
      if (error) throw error;
      fetchJobs(); // Refresh the job list
    } catch (error) {
      console.error('Error activating job:', error);
    }
  };

  const handleJobClick = (jobId) => {
    navigate(`/app/applicants/${jobId}`, { state: { jobId } });
  };

  const createDraftJob = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('employer')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      if (!userData.employer) {
        throw new Error("User's employer not set");
      }

      const { data, error } = await supabase
        .from('jobs')
        .insert([
          { 
            created_by: user.id,
            client_id: userData.employer,
            status: 'Draft',
            title: 'Untitled Job',
            description: '',
            duration: null,
            extension_possibility: null,
            travel: null,
            ideal_start_date: null,
            terms_and_conditions: false
          }
        ])
        .select()
        .single();

      if (error) throw error;
      
      navigate(`/app/edit-job/${data.job_id}`);
    } catch (error) {
      console.error('Error creating draft job:', error.message);
    }
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <>
      <Helmet>
        <title>Jobs | fetchConsultant</title>
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="dashboard" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold ml-4">Jobs</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="p-4">
            <div className="mb-4 flex justify-between items-center">
            <div className="flex items-center">
              <label htmlFor="statusFilter" className="mr-2">Status:</label>
              <select
                id="statusFilter"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="fetch-select"
              >
                <option value="All">All</option>
                <option value="Draft">Draft</option>
                <option value="Active">Active</option>
                <option value="Paused">Paused</option>
                <option value="Canceled">Canceled</option>
                <option value="Filled">Filled</option>
                <option value="Pending TC">Pending TC</option>
              </select>
            </div>
            <button 
              onClick={createDraftJob} 
              className="bg-brandPrimary text-white px-4 py-2 rounded-full"
            >
              Post Job
            </button>
          </div>
            {jobs.length === 0 ? (
              <p className="text-gray-600 text-center">No jobs found.</p>
            ) : (
              // Conditional rendering: mobile vs. desktop/tablet
              isMobile ? (
                <div className="grid grid-cols-1 gap-4">
                  {jobs.map((job) => (
                    <div 
                      key={job.job_id} 
                      className="bg-white text-left shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow cursor-pointer"
                      onClick={() => handleJobClick(job.job_id)}
                    >
                      {/* Job Title */}
                      <div className="mb-2">
                        <h2 className="text-xl font-bold text-gray-900">{job.title}</h2>
                      </div>

                      {/* Job Details */}
                      <div className="text-sm text-gray-500 mb-2">
                        {job.posted_at && (
                          <p>Posted: {new Date(job.posted_at).toLocaleDateString()}</p>
                        )}                        
                        <p>Status: {job.status}</p>
                        <p>Travel: {job.travel}</p>
                        <p>Duration: {job.duration}</p>
                      </div>

                      {/* MoreHorizontal Menu at the bottom-right */}
                      <div className="absolute bottom-4 right-4">
                        <MoreHorizontal 
                          size={24} 
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            toggleDropdown(job.job_id); // Toggle dropdown
                          }}
                        />
                        {activeDropdown === job.job_id && (
                          <div
                            className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                          >
                            {job.status !== 'Filled' && (
                              <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handleEditJob(e, job.job_id)}>
                                Edit Job
                              </div>
                            )}
                            {job.status === 'Active' && (
                              <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handlePauseJob(e, job.job_id)}>
                                Pause Job
                              </div>
                            )}
                            {job.status === 'Active' && (
                              <div className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100" onClick={(e) => handleDeleteJob(e, job.job_id)}>
                                Delete Job
                              </div>
                            )}
                            {(job.status === 'Paused' || job.status === 'Canceled') && (
                              <div className="block px-4 py-2 text-sm text-green-700 hover:bg-gray-100" onClick={(e) => handleActivateJob(e, job.job_id)}>
                                Activate Job
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-white text-left shadow-md rounded-lg">
                  <table className="min-w-full table-auto">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('title')}>
                          Title {sortConfig.key === 'title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('posted_at')}>
                          Posted {sortConfig.key === 'posted_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('status')}>
                          Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('travel')}>
                          Travel {sortConfig.key === 'travel' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('duration')}>
                          Duration {sortConfig.key === 'duration' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {jobs.map((job) => (
                        <tr key={job.job_id} className="hover:bg-gray-50 cursor-pointer" onClick={() => handleJobClick(job.job_id)}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-500">{job.title}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-500">{job.posted_at ? new Date(job.posted_at).toLocaleDateString() : ''}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-500">{job.status}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-500">{job.travel}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-500">{job.duration}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium relative">
                            <MoreHorizontal 
                              size={24} 
                              className="ml-auto cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent row click
                                toggleDropdown(job.job_id); // Toggle dropdown
                              }}
                            />
                            {activeDropdown === job.job_id && (
                              <div
                                className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                                ref={dropdownRef}
                              >
                                {job.status !== 'Filled' && (
                                  <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handleEditJob(e, job.job_id)}>
                                    Edit Job
                                  </div>
                                )}
                                {job.status === 'Active' && (
                                  <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handlePauseJob(e, job.job_id)}>
                                    Pause Job
                                  </div>
                                )}
                                {job.status === 'Active' && (
                                  <div className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100" onClick={(e) => handleDeleteJob(e, job.job_id)}>
                                    Delete Job
                                  </div>
                                )}
                                {(job.status === 'Paused' || job.status === 'Canceled') && (
                                  <div className="block px-4 py-2 text-sm text-green-700 hover:bg-gray-100" onClick={(e) => handleActivateJob(e, job.job_id)}>
                                    Activate Job
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientDashboard;