import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";

const ClientApproveHours = () => {
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Approve Hours',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchTimesheetEntries();
  }, []);

  function getWeekStart(date) {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  const fetchTimesheetEntries = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('timesheet_entries')
        .select(`
          *,
          users:consultant_id (name),
          jobs (title)
        `)
        .eq('jobs.created_by', user.id)
        .eq('status', 'submitted')
        .order('date', { ascending: true });

      if (error) throw error;

      const groupedEntries = data.reduce((acc, entry) => {
        const weekStart = getWeekStart(new Date(entry.date)).toISOString().split('T')[0];
        if (!acc[weekStart]) {
          acc[weekStart] = {
            consultant: entry.users.name,
            job: entry.jobs.title,
            entries: [],
            totalHours: 0,
          };
        }
        acc[weekStart].entries.push(entry);
        acc[weekStart].totalHours += entry.hours;
        return acc;
      }, {});

      setTimesheetEntries(Object.entries(groupedEntries));
    } catch (error) {
      console.error('Error fetching timesheet entries:', error.message);
    }
  };

  const handleApprove = async (weekStart) => {
    try {
      const entriesToApprove = timesheetEntries.find(([start]) => start === weekStart)[1].entries;
      const { error } = await supabase
        .from('timesheet_entries')
        .update({ status: 'approved' })
        .in('id', entriesToApprove.map(entry => entry.id));

      if (error) throw error;
      alert('Timesheet approved successfully');
      fetchTimesheetEntries();
    } catch (error) {
      console.error('Error approving timesheet:', error.message);
      alert('Error approving timesheet. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Approve Consultant Hours | fetchConsultant</title>
        <meta name="description" content="Review and approve weekly consultant timesheets for your projects on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Approve Timesheets</h1>
          <div className="flex items-center">
              <NotificationBell />
            </div>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            <h2 className="text-xl font-bold mb-4">Pending Timesheets</h2>
            {timesheetEntries.map(([weekStart, weekData]) => (
              <div key={weekStart} className="mb-6 p-4 border rounded">
                <h3 className="text-lg font-semibold">Week of {new Date(weekStart).toLocaleDateString()}</h3>
                <p>Consultant: {weekData.consultant}</p>
                <p>Job: {weekData.job}</p>
                <p>Total Hours: {weekData.totalHours}</p>
                <table className="w-full mt-2">
                  <thead>
                    <tr>
                      <th className="text-left">Date</th>
                      <th className="text-left">Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    {weekData.entries.map((entry) => (
                      <tr key={entry.id}>
                        <td>{new Date(entry.date).toLocaleDateString()}</td>
                        <td>{entry.hours}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  onClick={() => handleApprove(weekStart)}
                  className="fetch-button mt-4"
                >
                  Approve Timesheet
                </button>
              </div>
            ))}
          </div>
        </main>
      </div>
    </>
  );
};

export default ClientApproveHours;