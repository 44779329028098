import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { initializeFCM } from './firebase/fcm';
import { onMessageListener, requestForToken, setupTokenRefresh } from './firebaseConfig';
import './styles/tailwind.css';

// Component imports
import AddJob from './components/AddJob';
import ApplicantDetails from './components/ApplicantDetails';
import Applicants from './components/Applicants';
import ApplyJob from './components/ApplyJob';
import ApplyJobInterview from './components/ApplyJobInterview';
import AuthCallback from './components/AuthCallback';
import AuthScreen from './components/AuthScreen';
import ClientAddJobTermsAndConditions from './components/ClientAddJobTermsAndConditions';
import ClientApproveHours from './components/ClientApproveHours';
import ClientCreateInterviewNote from './components/ClientCreateInterviewNote';
import ClientDashboard from './components/ClientDashboard';
import ClientInterviewNotes from './components/ClientInterviewNotes';
import ClientInterviews from './components/ClientInterviews';
import ClientJoinTermsAndConditions from './components/ClientJoinTermsAndConditions';
import ClientOnboardingPersonal from './components/ClientOnboardingPersonal';
import ClientOnboardingProfessional from './components/ClientOnboardingProfessional';
import ClientOrConsultant from './components/ClientOrConsultant';
import ClientSettings from './components/ClientSettings';
import ConsultantApplyTermsAndConditions from './components/ConsultantApplyTermsAndConditions';
import ConsultantDashboard from './components/ConsultantDashboard';
import ConsultantInterviews from './components/ConsultantInterviews';
import ConsultantJobDescription from './components/ConsultantJobDescription';
import ConsultantJobsApplied from './components/ConsultantJobsApplied';
import ConsultantJoinTermsAndConditions from './components/ConsultantJoinTermsAndConditions';
import ConsultantOnboardingPersonal from './components/ConsultantOnboardingPersonal';
import ConsultantOnboardingProfessional from './components/ConsultantOnboardingProfessional';
import ConsultantRequests from './components/ConsultantRequests';
import ConsultantSettings from './components/ConsultantSettings';
import ConsultantTimesheet from './components/ConsultantTimesheet';
import EngagementManagement from './components/EngagementManagement';
import ForgotPassword from './components/ForgotPassword';
import InterviewFeedback from './components/InterviewFeedback';
import InterviewFeedbackView from './components/InterviewFeedbackView';
import NotFoundPage from './components/NotFoundPage';
import NotificationBanner from './components/NotificationBanner';
import Notifications from './components/Notifications';
import Notifications2 from './components/Notifications2';
import ProposeInterview from './components/ProposeInterview';
import ProtectedRoute from './components/ProtectedRoute';
import RequestSecondInterview from './components/RequestSecondInterview';
import ResetPassword from './components/ResetPassword';
import ReviewBenefits from './components/ReviewBenefits';
import Reviews from './components/Reviews';
import RoleBasedRoute from './components/RoleBasedRoute';
import ScheduleInterview from './components/ScheduleInterview';
import SolicitReview from './components/SolicitReview';
import SubmitReview from "./components/SubmitReview";
import SystemAdminDashboard from './components/SystemAdminDashboard';
import SystemAdminHoursDashboard from './components/SystemAdminHoursDashboard';
import SystemAdminInterviewNotes from './components/SystemAdminInterviewNotes';
import SystemAdminUsers from './components/SystemAdminUsers';
import VerifyEmail from './components/VerifyEmail';
import VerifyWorkEmail from './components/VerifyWorkEmail';
import WelcomeClient from "./components/WelcomeClient";
import WelcomeConsultant from "./components/WelcomeConsultant";
import Home from "./components/Home"
import Refer from "./components/Refer.js";
import SolicitRefer from "./components/SolicitRefer.js";
import AllApplicants from "./components/AllApplicants.js";
import ClientOfferContract from "./components/ClientOfferContract.js";
import ConsultantLanding from './components/ConsultantLanding';
import ClientLanding from './components/ClientLanding';
import Terms from './components/Terms';

function App() {
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'app_opened');
  
    // Initialize FCM, but don't request for token here
    initializeFCM();
  
    // This part is for handling foreground messages
    const unsubscribe = onMessageListener().then((payload) => {
      console.log('Received foreground message ', payload);
    }).catch((err) => console.log('Failed to subscribe to messages:', err));
  
    return () => {
      unsubscribe.catch((err) => console.log('Failed to unsubscribe from messages:', err));
    };
  }, []);
  
  return (
    <Router>
      <div className="App">
        <NotificationBanner />
        <Routes>
          {/* Public routes */}
          <Route path="/app/auth" element={<AuthScreen />} />
          <Route path="/app/" element={<Navigate to="/app/auth" replace />} />
          <Route path="/app/forgot-password" element={<ForgotPassword />} />
          <Route path="/app/reset-password" element={<ResetPassword />} />
          <Route path="/app/verify-work-email" element={<VerifyWorkEmail />} />
          <Route path="/app/verify-email" element={<VerifyEmail />} />
          <Route path="/app/auth/callback" element={<AuthCallback />} />
          <Route path="/app/review-benefits" element={<ReviewBenefits />} />
          <Route path="/app/job/:jobId" element={<ConsultantJobDescription />} />
          <Route path="/" element={<Home />} />
          <Route path="/consultants" element={<ConsultantLanding />} />
          <Route path="/clients" element={<ClientLanding />} />
          <Route path="/terms" element={<Terms />} />

          {/* Protected Common routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/app/client-or-consultant" element={<RoleBasedRoute component={ClientOrConsultant} allowedTypes={[1, 5]} fallbackPath="/app" />} />
            <Route path="/app/submit-review/:token" element={<RoleBasedRoute component={SubmitReview} allowedTypes={[2, 4, 5]} fallbackPath="/app/review-benefits" />} />
            <Route path="/app/notifications" element={<RoleBasedRoute component={Notifications} allowedTypes={[2, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/notifications2" element={<RoleBasedRoute component={Notifications2} allowedTypes={[2, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/interview-feedback/:userJobId" element={<RoleBasedRoute component={InterviewFeedback} allowedTypes={[2, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/view-feedback/:userJobId" element={<RoleBasedRoute component={InterviewFeedbackView} allowedTypes={[2, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/engagement-management" element={<RoleBasedRoute component={EngagementManagement} allowedTypes={[2, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/refer" element={<RoleBasedRoute component={Refer} allowedTypes={[2, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/solicit-refer" element={<RoleBasedRoute component={SolicitRefer} allowedTypes={[2, 4, 5]} fallbackPath="/app" />} />
            
            {/* Consultant routes */}
            <Route path="/app/consultant-onboarding-personal" element={<RoleBasedRoute component={ConsultantOnboardingPersonal} allowedTypes={[1, 2, 5]} fallbackPath="/app" />} /> {console.log("App.js - ConsultantOnboardingPersonal route rendered")}
            <Route path="/app/consultant-onboarding-professional" element={<RoleBasedRoute component={ConsultantOnboardingProfessional} allowedTypes={[1, 2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-dashboard" element={<RoleBasedRoute component={ConsultantDashboard} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/solicit-review" element={<RoleBasedRoute component={SolicitReview} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-edit-profile" element={<RoleBasedRoute component={ConsultantOnboardingPersonal} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-edit-profile-professional" element={<RoleBasedRoute component={ConsultantOnboardingProfessional} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-jobs-applied" element={<RoleBasedRoute component={ConsultantJobsApplied} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-settings" element={<RoleBasedRoute component={ConsultantSettings} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/apply-job/:jobId" element={<RoleBasedRoute component={ApplyJob} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-join-terms-and-conditions/:jobId" element={<RoleBasedRoute component={ConsultantJoinTermsAndConditions} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-apply-terms-and-conditions/:jobId" element={<RoleBasedRoute component={ConsultantApplyTermsAndConditions} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/apply-job-interview/:jobId" element={<RoleBasedRoute component={ApplyJobInterview} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/reviews" element={<RoleBasedRoute component={Reviews} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-timesheet" element={<RoleBasedRoute component={ConsultantTimesheet} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-requests" element={<RoleBasedRoute component={ConsultantRequests} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/consultant-interviews" element={<RoleBasedRoute component={ConsultantInterviews} allowedTypes={[2, 5]} fallbackPath="/app" />} />
            <Route path="/app/welcome-consultant" element={<RoleBasedRoute component={WelcomeConsultant} allowedTypes={[2, 5]} fallbackPath="/app" />} />

            {/* Client routes */}
            <Route path="/app/client-onboarding-personal" element={<RoleBasedRoute component={ClientOnboardingPersonal} allowedTypes={[1, 3, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-onboarding-professional" element={<RoleBasedRoute component={ClientOnboardingProfessional} allowedTypes={[1, 3, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-dashboard" element={<RoleBasedRoute component={ClientDashboard} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-edit-profile" element={<RoleBasedRoute component={ClientOnboardingPersonal} allowedTypes={[3, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-edit-profile-professional" element={<RoleBasedRoute component={ClientOnboardingProfessional} allowedTypes={[3, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/applicants/:jobId" element={<RoleBasedRoute component={Applicants} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/applicant/:userJobId" element={<RoleBasedRoute component={ApplicantDetails} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/schedule-interview/:userJobId" element={<RoleBasedRoute component={ScheduleInterview} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/propose-interview/:userJobId" element={<RoleBasedRoute component={ProposeInterview} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/request-second-interview/:userJobId" element={<RoleBasedRoute component={RequestSecondInterview} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-settings" element={<RoleBasedRoute component={ClientSettings} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-interviews" element={<RoleBasedRoute component={ClientInterviews} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-interview-notes" element={<RoleBasedRoute component={ClientInterviewNotes} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/create-interview-note" element={<RoleBasedRoute component={ClientCreateInterviewNote} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/edit-interview-note/:id" element={<RoleBasedRoute component={ClientCreateInterviewNote} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/add-job" element={<RoleBasedRoute component={AddJob} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-join-terms-and-conditions/:jobId" element={<RoleBasedRoute component={ClientJoinTermsAndConditions} allowedTypes={[3, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-add-job-terms-and-conditions/:jobId" element={<RoleBasedRoute component={ClientAddJobTermsAndConditions} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/edit-job/:jobId" element={<RoleBasedRoute component={AddJob} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-approve-hours" element={<RoleBasedRoute component={ClientApproveHours} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/client-offer-contract/:userJobId" element={<RoleBasedRoute component={ClientOfferContract} allowedTypes={[4, 5]} fallbackPath="/app" />} />
            <Route path="/app/welcome-client" element={<RoleBasedRoute component={WelcomeClient} allowedTypes={[3, 4, 5]} fallbackPath="/app" />} />
            <Route path="/app/all-applicants" element={<RoleBasedRoute component={AllApplicants} allowedTypes={[4, 5]} fallbackPath="/app" />} />

            {/* Admin route */}
            <Route path="/app/system-admin-dashboard" element={<RoleBasedRoute component={SystemAdminDashboard} allowedTypes={[5]} fallbackPath="/app" />} />
            <Route path="/app/system-admin-hours-dashboard" element={<RoleBasedRoute component={SystemAdminHoursDashboard} allowedTypes={[5]} fallbackPath="/app" />} />
            <Route path="/app/system-admin-users" element={<RoleBasedRoute component={SystemAdminUsers} allowedTypes={[5]} fallbackPath="/app" />} />
            <Route path="/app/system-admin-interview-notes/:interviewId" element={<RoleBasedRoute component={SystemAdminInterviewNotes} allowedTypes={[5]} fallbackPath="/app" />} />
          </Route>

          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;