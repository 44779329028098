import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import ClientHamburger from './ClientHamburger';
import { Menu, Search, Star, Edit, Trash2, MoreHorizontal, Filter } from 'lucide-react';
import { getAnalytics, logEvent } from "firebase/analytics";

const ClientInterviewNotes = () => {
  const [notes, setNotes] = useState([]);
  const [aiSummaries, setAiSummaries] = useState({});
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('All Notes');
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState('');
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Interview Notes',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchNotes();
    fetchConsultants();

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, [sortConfig]);

  useEffect(() => {
    if (location.state?.consultantId) {
      setSelectedConsultant(location.state.consultantId);
      // Immediately filter notes when a consultant is selected from ClientInterviews
      filterNotes(notes, location.state.consultantId);
    }
  }, [location, notes]);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchConsultants = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('user_id, name')
        .eq('user_type_id', 2);  // Assuming 2 is the user_type_id for consultants

      if (error) throw error;
      setConsultants(data);
    } catch (error) {
      console.error('Error fetching consultants:', error);
    }
  };

  const fetchNotes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('interview_notes')
        .select(`
          *,
          notewriter:users!fk_notewriter(name),
          consultant:users!fk_consultant(name),
          interview:interviews (
            id,
            interview_time,
            ai_summary,
            user_job:user_jobs (
              job:jobs (title)
            )
          )
        `)
        .eq('notewriter_id', user.id)
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });
  
      if (error) throw error;
      setNotes(data);
      filterNotes(data);
  
      const summaries = {};
      data.forEach(note => {
        if (note.interview && note.interview.ai_summary) {
          summaries[note.interview.id] = note.interview.ai_summary;
        }
      });
      setAiSummaries(summaries);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterNotes = (notesToFilter = notes) => {
    const filtered = notesToFilter.filter(note => 
      (activeTab === 'All Notes' || note.note_type === activeTab) &&
      (selectedConsultant === '' || note.consultant_id === selectedConsultant) &&
      (note.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
       note.notewriter.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       note.consultant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       note.skills?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       note.strengths?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       note.weaknesses?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredNotes(filtered);
  };

  useEffect(() => {
    filterNotes();
  }, [activeTab, searchTerm, selectedConsultant, notes]);

  const handleEditNote = (note) => {
    navigate('/app/create-interview-note', { state: { note, consultantId: note.consultant_id } });
  };

  const handleCreateNote = () => {
    navigate('/app/create-interview-note', { state: { consultantId: selectedConsultant } });
  };

  const handleDeleteNote = async (noteId) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        const { error } = await supabase
          .from('interview_notes')
          .delete()
          .eq('id', noteId);

        if (error) throw error;
        fetchNotes();
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    }
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const toggleDropdown = (noteId) => {
    setActiveDropdown(activeDropdown === noteId ? null : noteId);
  };

  const renderNotesTable = () => (
    <div className="bg-white shadow-md rounded-lg overflow-x-auto">
      <table className="min-w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('notewriter.name')} className="cursor-pointer">
                Notewriter {sortConfig.key === 'notewriter.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('created_at')} className="cursor-pointer">
                Date {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('note_type')} className="cursor-pointer">
                Type {sortConfig.key === 'note_type' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Content</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Rating</th>
            <th className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {filteredNotes.map((note) => (
            <tr key={note.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 text-sm text-gray-700">{note.notewriter.name}</td>
              <td className="px-6 py-4 text-sm text-gray-700">{new Date(note.created_at).toLocaleString()}</td>
              <td className="px-6 py-4 text-sm text-gray-700">{note.note_type}</td>
              <td className="px-6 py-4 text-sm text-gray-700">
                <div className="max-h-20 overflow-y-auto" dangerouslySetInnerHTML={{ __html: note.content }} />
              </td>
              <td className="px-6 py-4">
                <div className="flex">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      size={16}
                      className={star <= note.feedback_score ? 'text-yellow-400' : 'text-gray-300'}
                      fill={star <= note.feedback_score ? 'currentColor' : 'none'}
                    />
                  ))}
                </div>
              </td>
              <td className="px-6 py-4 text-right">
                <MoreHorizontal
                  size={24}
                  className="cursor-pointer"
                  onClick={() => toggleDropdown(note.id)}
                />
                {activeDropdown === note.id && (
                  <div ref={dropdownRef} className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleEditNote(note)}
                    >
                      View Note
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleDeleteNote(note.id)}
                    >
                      Delete Note
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderNotesCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {filteredNotes.map((note) => (
        <div key={note.id} className="bg-white text-left shadow-md rounded-lg p-4 relative">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="font-semibold">{note.notewriter.name}</p>
              <p className="text-sm text-gray-500">{new Date(note.created_at).toLocaleString()}</p>
              <p className="text-sm text-gray-500">{note.note_type} Note</p>
            </div>
            <div className="flex items-center">
              <MoreHorizontal
                size={24}
                className="cursor-pointer"
                onClick={() => toggleDropdown(note.id)}
              />
              {activeDropdown === note.id && (
                <div ref={dropdownRef} className="absolute right-0 mt-8 w-48 bg-white rounded-md shadow-lg z-10">
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleEditNote(note)}
                  >
                    Edit Note
                  </div>
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleDeleteNote(note.id)}
                  >
                    Delete Note
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-2 max-h-20 text-left overflow-y-auto" dangerouslySetInnerHTML={{ __html: note.content }} />
          <div className="mt-2">
            <div className="flex">
              {[1, 2, 3, 4, 5].map((star) => (
                <Star
                  key={star}
                  size={16}
                  className={star <= note.feedback_score ? 'text-yellow-400' : 'text-gray-300'}
                  fill={star <= note.feedback_score ? 'currentColor' : 'none'}
                />
              ))}
            </div>
          </div>
          {note.skills && (
            <div className="mt-2">
              <p className="text-sm font-medium text-gray-500">Key Skills:</p>
              <p>{note.skills}</p>
            </div>
          )}
          {note.strengths && (
            <div className="mt-2">
              <p className="text-sm font-medium text-gray-500">Strengths:</p>
              <p>{note.strengths}</p>
            </div>
          )}
          {note.weaknesses && (
            <div className="mt-2">
              <p className="text-sm font-medium text-gray-500">Areas for Improvement:</p>
              <p>{note.weaknesses}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Helmet>
        <title>Interview Notes | fetchConsultant</title>
        <meta name="description" content="View and manage interview notes and AI-generated summaries for candidates on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="notes" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Interview Notes</h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={handleCreateNote}
                className="bg-indigo-600 text-white px-4 py-2 rounded mr-4"
              >
                Create Note
              </button>
              <NotificationBell />
            </div>
          </header>
          
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="bg-white shadow rounded-lg p-6">
              <div className="mb-6">
                <div className="flex items-center mb-4">
                  <div className="relative flex-grow mr-4">
                    <Search size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search notes..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-12 py-2 w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                  <div className="relative">
                    <Filter size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <select
                      value={selectedConsultant}
                      onChange={(e) => setSelectedConsultant(e.target.value)}
                      className="pl-10 pr-12 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <option value="">All Consultants</option>
                      {consultants.map((consultant) => (
                        <option key={consultant.user_id} value={consultant.user_id}>
                          {consultant.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex space-x-4 mb-4 overflow-x-auto">
                  {['All Notes', 'Interview', 'General', 'Other'].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`px-3 py-2 rounded-md text-sm font-medium whitespace-nowrap ${
                        activeTab === tab
                          ? 'bg-indigo-100 text-indigo-700'
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>

              {isMobile ? renderNotesCards() : renderNotesTable()}

              {Object.keys(aiSummaries).length > 0 && (
                <div className="mt-8">
                  <h3 className="text-lg font-semibold mb-4">AI-Generated Summaries</h3>
                  {Object.entries(aiSummaries).map(([interviewId, summary]) => (
                    <div key={interviewId} className="bg-gray-50 p-4 rounded-lg mb-4">
                      <h4 className="font-semibold mb-2">Interview Summary</h4>
                      <p>{summary}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientInterviewNotes;