import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import ConsultantHamburger from './ConsultantHamburger';

const Notifications = () => {
  const [reviewNotifications, setReviewNotifications] = useState([]);
  const [generalNotifications, setGeneralNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Notifications',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchAllNotifications();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchAllNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      // Fetch unread notifications count
      const { count, error: unreadError } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (unreadError) throw unreadError;
      setUnreadNotifications(count);

      // Fetch review notifications
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('login_email, work_email')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      const { data: reviewData, error: reviewError } = await supabase
        .from('review_tokens')
        .select(`
          *,
          consultant:users!review_tokens_consultant_id_fkey(name)
        `)
        .is('used_at', null)
        .or(`reviewer_email.eq.${userData.login_email},reviewer_email.eq.${userData.work_email}`)
        .order('created_at', { ascending: false });

      if (reviewError) throw reviewError;

      setReviewNotifications(reviewData);

      // Fetch general notifications from notifications_log
      const { data: generalData, error: generalError } = await supabase
        .from('notifications_log')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (generalError) throw generalError;

      setGeneralNotifications(generalData);

      // Mark all notifications as read
      await supabase
        .from('notifications_log')
        .update({ is_read: true })
        .eq('user_id', user.id)
        .eq('is_read', false);

    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReviewNotificationClick = (notification) => {
    navigate(`/app/submit-review/${notification.token}`);
  };

  const handleGeneralNotificationClick = (notification) => {
    // Add any specific actions for general notifications here
  };

  if (isLoading) {
    return <div>Loading notifications...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Notifications | fetchConsultant</title>
        <meta name="description" content="View and manage your notifications on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        {/* Display ConsultantHamburger or ClientHamburger based on user_type */}
        {user && user.user_type === 2 && (
          <ConsultantHamburger user={user} currentPage="notifications" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}
        {user && user.user_type === 4 && (
          <ClientHamburger user={user} currentPage="notifications" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}
  
        <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Solicit Review</h1>
            </div>
            <div className="flex items-center">
              <button onClick={() => navigate('/app/notifications')} className="relative mr-4">
                <Bell size={24} />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadNotifications}
                  </span>
                )}
              </button>
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
          
          {/* Only show Pending Reviews section if there are pending reviews */}
          {reviewNotifications.length > 0 && (
            <>
              <h2 className="text-2xl font-bold mb-4">Pending Reviews</h2>
              <ul className="space-y-4">
                {reviewNotifications.map((notification) => (
                  <li key={notification.id} className="bg-white shadow rounded-lg p-4 flex items-center justify-between">
                    <div className="flex items-center">
                      <Bell className="h-6 w-6 text-brandPrimary mr-3" />
                      <div>
                        <p
                          className="text-sm font-medium text-gray-900 cursor-pointer hover:underline"
                          onClick={() => handleReviewNotificationClick(notification)}
                        >
                          {notification.consultant.name} has requested a {notification.review_type} review from you
                        </p>
                        <p className="text-sm text-gray-500">
                          Requested on: {new Date(notification.created_at).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => handleReviewNotificationClick(notification)}
                      className="bg-brandPrimary text-white px-4 py-2 rounded hover:bg-brandHover"
                    >
                      Submit Review
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
  
          <h2 className="text-2xl font-bold mt-8 mb-4">General Notifications</h2>
          {generalNotifications.length === 0 ? (
            <p className="text-gray-600">You have no general notifications.</p>
          ) : (
            <ul className="space-y-4">
              {generalNotifications.map((notification) => (
                <li
                  key={notification.id}
                  className="bg-white shadow rounded-lg p-4"
                  onClick={() => handleGeneralNotificationClick(notification)}
                >
                  <p className="font-semibold">{notification.content}</p>
                  <p className="text-sm text-gray-500">
                    {new Date(notification.created_at).toLocaleString()}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </main>
      </div>
    </>
  );
};

export default Notifications;