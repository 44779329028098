// src/components/VerifyWorkEmail.js

import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { supabase } from '../supabaseClient';

// Import slick carousel styles
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const VerifyWorkEmail = () => {
  const [status, setStatus] = useState('verifying');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Verify Work Email',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const verifyEmail = async () => {
      const token = new URLSearchParams(location.search).get('token');
      if (!token) {
        setStatus('error');
        return;
      }
    
      try {
        const { data, error } = await supabase
          .from('users')
          .select('work_email')
          .eq('work_email_verification_token', token)
          .single();
    
        if (error) throw error;
    
        const workEmailDomain = data.work_email.split('@')[1];
        const { data: clients } = await supabase
          .from('clients')
          .select('email_domain1, email_domain2');
    
        const matchingClient = clients.find(client =>
          client.email_domain1 === workEmailDomain || client.email_domain2 === workEmailDomain
        );
    
        await supabase
          .from('users')
          .update({ 
            work_email_verified_at: new Date().toISOString(),
            work_email_verification_token: null,
            user_type_id: matchingClient ? 4 : 3
          })
          .eq('work_email_verification_token', token);
    
        setStatus('success');
        setTimeout(() => {
          if (matchingClient) {
            navigate('/app/welcome-client');
          } else {
            navigate('/app/welcome-client');
          }
        }, 3000);
      } catch (error) {
        console.error('Error verifying email:', error);
        setStatus('error');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <Helmet>
        <title>Verify Work Email | fetchConsultant</title>
        <meta name="description" content="Verify your work email to complete your client registration on fetchConsultant." />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
        <div className="max-w-4xl w-full bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="md:flex">
            <div className="md:flex-shrink-0">
            <img className="h-48 w-full object-cover md:w-48" src="/welcome-to-fetch-transparent.png" alt="Welcome to fetchConsultant" />
            </div>
            <div className="p-8">
              <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Verify Your Work Email</div>
              {status === 'verifying' && (
                <div>
                  <h2 className="text-2xl font-bold mb-4">Verifying your email...</h2>
                  <p className="text-gray-600">Please wait while we confirm your email address.</p>
                </div>
              )}
              {status === 'success' && (
                <div>
                  <h2 className="text-2xl font-bold mb-4">Email Verified Successfully!</h2>
                  <p className="text-gray-600">Your client account has been verified. You will be redirected to the Client Dashboard in a few seconds.</p>
                </div>
              )}
              {status === 'error' && (
                <div>
                  <h2 className="text-2xl font-bold mb-4">Verification Error</h2>
                  <p className="text-gray-600">There was an error verifying your email. Please try again or contact support.</p>
                  <button
                    onClick={() => navigate('/app/login')}
                    className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
                  >
                    Return to Login
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Why Choose fetchConsultant?</h2>
            <Slider {...carouselSettings} className="fetchCarousel">
              <div className="px-4">
                <h3 className="text-xl font-semibold mb-2">Privacy and Control</h3>
                <p>While LinkedIn generated $15.7 billion in 2023 from Talent Solutions, your information on fetchConsultant remains private. Your profile is never shared or sold. Oh, and no cringe news feed either!</p>
              </div>
              <div className="px-4">
                <h3 className="text-xl font-semibold mb-2">Disrupting the Consulting Space</h3>
                <p>Accenture earned $64.1 billion in revenue in 2023. With fetchConsultant, we're disrupting this space by cutting out the middleman. This means more savings for clients and higher earnings for consultants.</p>
              </div>
              <div className="px-4">
                <h3 className="text-xl font-semibold mb-2">Fair Economics</h3>
                <p>Traditional consulting firms have an incentive to charge clients as much as possible while paying consultants as little as possible. fetchConsultant aligns incentives, ensuring fair rates for both clients and consultants.</p>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyWorkEmail;