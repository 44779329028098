import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
import { Menu, MoreHorizontal } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import NotificationPermissionWarning from './NotificationPermissionWarning';
import Spinner from './Spinner';

const ConsultantJobsApplied = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const observer = useRef();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Applied Jobs',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    checkMobile();

    const messaging = getMessaging();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      if (payload.notification && payload.data && payload.data.url) {
        toast.info(
          <div>
            <p>{payload.notification.body}</p>
            <button 
              onClick={() => navigate(payload.data.url)}
              className="bg-brandPrimary text-white px-4 py-2 rounded mt-2"
            >
              View Details
            </button>
          </div>,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    });

    window.addEventListener('resize', checkMobile);

    return () => {
      unsubscribe();
      window.removeEventListener('resize', checkMobile);
    };
  }, [navigate]);

  useEffect(() => {
    fetchAppliedJobs();
  }, [page, sortConfig]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchAppliedJobs = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      let query = supabase
        .from('user_jobs')
        .select(`
          *,
          job:jobs (
            *,
            client:clients (name)
          ),
          interviews (
            id,
            interview_time,
            zoom_meeting_link,
            feedback,
            rating
          )
        `)
        .eq('user_id', user.id)
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' })
        .range(page * 10, (page + 1) * 10 - 1);

      const { data, error } = await query;

      if (error) throw error;

      if (page === 0) {
        setAppliedJobs(data);
      } else {
        setAppliedJobs(prevJobs => [...prevJobs, ...data]);
      }

      setHasMore(data.length === 10);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching applied jobs:', error.message);
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setAppliedJobs([]);
    setPage(0);
    await fetchAppliedJobs();
    setIsRefreshing(false);
  };

  const toggleDropdown = (jobId) => {
    setActiveDropdown(activeDropdown === jobId ? null : jobId);
  };

  const handleJobClick = (jobId) => {
    trackJobView(jobId);
    navigate(`/app/job/${jobId}`);
  };

  const handleUpdateApplication = (e, jobId, status) => {
    e.stopPropagation();
    navigate(`/app/apply-job/${jobId}`);
  };

  const handleRefer = (e, jobId) => {
    e.stopPropagation();
    navigate('/app/solicit-refer', { state: { jobId } });
  };

  const handleCancelApplication = async (e, userJobId) => {
    e.stopPropagation();
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({ status: 'Application Withdrawn' })
        .eq('user_job_id', userJobId);

      if (error) throw error;

      toast.success('Application withdrawn successfully');
      fetchAppliedJobs();
    } catch (error) {
      console.error('Error withdrawing application:', error.message);
      toast.error('Failed to withdraw application');
    }
  };

  const trackJobView = async (jobId) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { error } = await supabase
        .from('job_views')
        .insert({
          job_id: jobId,
          user_id: user.id,
          viewed_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error tracking job view:', error.message);
    }
  };

  const lastJobElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const formatTimeAgo = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const diffInSeconds = Math.floor((now - postedDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <>
      <Helmet>
        <title>My Applications | fetchConsultant</title>
        <meta name="description" content="View and manage your applied Epic consulting opportunities on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="my applications" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Applied Jobs</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <NotificationPermissionWarning />
          <main className="flex-grow container mx-auto px-4 py-8">
            <PullToRefresh
              onRefresh={handleRefresh}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              {isMobile ? (
                <div className="grid grid-cols-1 gap-4">
                  {appliedJobs.map((job, index) => (
                    <div 
                      key={job.user_job_id} 
                      className="bg-white text-left shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow cursor-pointer"
                      onClick={() => handleJobClick(job.job.job_id)}
                      ref={index === appliedJobs.length - 1 ? lastJobElementRef : null}
                    >
                      <div className="mb-2">
                        <h2 className="text-xl font-bold text-gray-900">{job.job.title}</h2>
                        <p className="text-sm text-gray-600">{job.job.client.name}</p>
                      </div>
                      <div className="text-sm text-gray-500 mb-2">
                        <p>Applied: {formatTimeAgo(job.created_at)}</p>
                        <p>Status: {job.status}</p>
                        <p>Travel: {job.job.travel}</p>
                        <p>Duration: {job.job.duration}</p>
                      </div>
                      <div className="absolute bottom-4 right-4">
                        <MoreHorizontal 
                          size={24} 
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(job.user_job_id);
                          }}
                        />
                        {activeDropdown === job.user_job_id && (
                          <div className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer">
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleJobClick(job.job.job_id);
                              }}
                            >
                              Job Description
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                              onClick={(e) => handleRefer(e, job.job.job_id)}
                            >
                              Refer
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              onClick={(e) => handleUpdateApplication(e, job.job.job_id)}
                            >
                              {job.status === 'Application Withdrawn' ? 'Reapply' : 'Update Application'}
                            </div>
                            <div
                              className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 cursor-pointer"
                              onClick={(e) => handleCancelApplication(e, job.user_job_id)}
                            >
                              Cancel Application
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-white text-left shadow-md rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('job.title')}>
                          Job Title {sortConfig.key === 'job.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('job.client.name')}>
                          Client {sortConfig.key === 'job.client.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('created_at')}>
                          Applied {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('status')}>
                          Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {appliedJobs.map((job, index) => (
                        <tr 
                          key={job.user_job_id}
                          className="hover:bg-gray-50 cursor-pointer"
                          onClick={() => handleJobClick(job.job.job_id)}
                          ref={index === appliedJobs.length - 1 ? lastJobElementRef : null}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">{job.job.title}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">{job.job.client.name}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">{formatTimeAgo(job.created_at)}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {job.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-rigth text-sm font-medium relative">
                            <MoreHorizontal 
                              size={24} 
                              className="ml-auto cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(job.user_job_id);
                              }}
                            />
                            {activeDropdown === job.user_job_id && (
                              <div ref={dropdownRef} className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-50 cursor-pointer">
                                <div
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleJobClick(job.job.job_id);
                                  }}
                                >
                                  Job Description
                                </div>
                                <div
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={(e) => handleRefer(e, job.job.job_id)}
                                >
                                  Refer
                                </div>
                                <div
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={(e) => handleUpdateApplication(e, job.job.job_id)}
                                >
                                  Update Application
                                </div>
                                <div
                                  className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                                  onClick={(e) => handleCancelApplication(e, job.user_job_id)}
                                >
                                  Cancel Application
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {loading && <p className="text-center mt-4">Loading...</p>}
            </PullToRefresh>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConsultantJobsApplied;