import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Star, Menu, MoreHorizontal } from 'lucide-react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import { UserContext } from '../UserContext';  // Import UserContext to access the user data

const statusProgress = {
  'Applied': 1,
  'Interview Scheduled': 2,
  'Second Interview Requested': 3,
  'Second Interview Scheduled': 4,
  'Contract Offered': 5,
};

const Applicants = () => {
  const [applicants, setApplicants] = useState([]);
  const [noApplicants, setNoApplicants] = useState(false);
  const [statusFilter, setStatusFilter] = useState('All');
  const { user } = useContext(UserContext);  // Access user data from context
  const [job, setJob] = useState(null);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'submitted_at', direction: 'asc' });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [activeDropdown, setActiveDropdown] = useState(null); // State to track which dropdown is open
  const dropdownRef = useRef(null);

  const toggleDropdown = (userJobId) => {
    setActiveDropdown(activeDropdown === userJobId ? null : userJobId); // Toggle dropdown visibility
  };
  

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Applicants',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchApplicants();
    fetchJobDetails();
  }, [jobId, sortConfig, statusFilter]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null); // Close dropdown if clicked outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchApplicants = async () => {
    try {
      let query = supabase
        .from('user_jobs')
        .select(`
          user_job_id,
          submitted_at,
          consultant_rate,
          status,
          users:user_jobs_user_id_fkey (
            name,
            photo,
            linkedin_url,
            average_rating,
            former_epic
          )
        `)
        .eq('job_id', jobId)
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });
  
      if (statusFilter !== 'All') {
        query = query.eq('status', statusFilter);
      }
  
      const { data, error } = await query;
  
      if (!data || data.length === 0) {
        setApplicants([]);
        setNoApplicants(true);
      } else {
        setApplicants(data);
        setNoApplicants(false);
      }
    } catch (error) {
      console.error('Error fetching applicants:', error.message);
      setNoApplicants(true);
    }
  };

  const fetchJobDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('title')
        .eq('job_id', jobId)
        .single();

      if (error) throw error;
      setJob(data);
    } catch (error) {
      console.error('Error fetching job details:', error.message);
    }
  };

  const renderStars = (rating) => {
    if (!rating) {
      return <span>No ratings yet</span>;
    }
  
    const stars = [];
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
    for (let i = 1; i <= 5; i++) {
      let starFill = Math.min(Math.max(roundedRating - (i - 1), 0), 1);
      if (starFill === 1) {
        // Full star
        stars.push(<Star key={i} size={20} fill="gold" stroke="gold" />);
      } else if (starFill > 0) {
        // Partial star
        stars.push(
          <div key={i} className="relative">
            <Star size={20} fill="none" stroke="gold" />
            <div
              className="absolute top-0 left-0 overflow-hidden"
              style={{ width: `${starFill * 100}%` }}
            >
              <Star size={20} fill="gold" stroke="gold" />
            </div>
          </div>
        );
      } else {
        // Empty star
        stars.push(<Star key={i} size={20} fill="none" stroke="gold" />);
      }
    }
    return stars;
  };

  const toggleSelectApplicant = (userJobId) => {
    setSelectedApplicants((prev) =>
      prev.includes(userJobId)
        ? prev.filter((id) => id !== userJobId)
        : [...prev, userJobId]
    );
  };

  const handleReject = async (userJobId) => {
    try {
      // Step 1: Fetch the current status
      const { data, error: fetchError } = await supabase
        .from('user_jobs')
        .select('status')
        .eq('user_job_id', userJobId)
        .single(); // Use .single() since we expect one record
  
      if (fetchError) throw fetchError;
  
      const currentStatus = data.status;
  
      // Step 2: Determine the new status
      const newStatus = currentStatus === 'Applied' ? 'Interview Rejected' : 'Contract Not Offered';
  
      // Step 3: Update the status
      const { error: updateError } = await supabase
        .from('user_jobs')
        .update({ status: newStatus })
        .eq('user_job_id', userJobId);
  
      if (updateError) throw updateError;
  
      // Step 4: Optionally refetch applicants to refresh the list
      fetchApplicants();
    } catch (error) {
      console.error('Error rejecting applicant:', error.message);
    }
  };

  const handleRejectSelected = async () => {
    try {
      // Step 1: Update applicants with status 'Applied' to 'Interview Rejected'
      const { error: errorApplied } = await supabase
        .from('user_jobs')
        .update({ status: 'Interview Rejected' })
        .in('user_job_id', selectedApplicants)
        .eq('status', 'Applied');
  
      if (errorApplied) throw errorApplied;
  
      // Step 2: Update applicants with other statuses to 'Contract Not Offered'
      const { error: errorOthers } = await supabase
        .from('user_jobs')
        .update({ status: 'Contract Not Offered' })
        .in('user_job_id', selectedApplicants)
        .neq('status', 'Applied');
  
      if (errorOthers) throw errorOthers;
  
      // Refresh the applicants list
      fetchApplicants();
    } catch (error) {
      console.error('Error rejecting applicants:', error.message);
    }
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const handleRowClick = (userJobId) => {
    navigate(`/app/applicant/${userJobId}`);
  };

  const renderProgressDots = (status) => {
    const progress = statusProgress[status] || 1;
    return (
      <div className="flex items-center space-x-1">
        {Array(5).fill(0).map((_, index) => (
          <span
            key={index}
            className={`h-2 w-2 rounded-full ${index + 1 <= progress ? 'bg-brandPrimary' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Applicants for {job?.title} | fetchConsultant</title>
        <meta name="description" content={`View and manage applicants for ${job?.title} on fetchConsultant.`} />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="applicants" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate('/app/client-dashboard')} />
              <h1 className="text-xl font-bold ml-4">Applicants for {job?.title}</h1>
            </div>
            <div className="flex items-center">
              {selectedApplicants.length > 0 && (
                <button 
                  onClick={handleRejectSelected} 
                  className="bg-red-500 text-white px-4 py-2 rounded-full mr-4">
                  Reject
                </button>
              )}
              <button 
                onClick={() => navigate('/app/solicit-refer', { state: { jobId } })}
                className="bg-brandPrimary text-white px-4 py-2 rounded-full mr-4">
                Refer Candidate
              </button>
              <NotificationBell />
            </div>
          </header>

          <main className="p-4">
            <div className="mb-4 flex justify-between items-center">
              <div className="flex items-center">
                <label htmlFor="statusFilter" className="mr-2">Status:</label>
                <select
                  id="statusFilter"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All</option>
                  <option value="Applied">Applied</option>
                  <option value="Interview Scheduled">Interview Scheduled</option>
                  <option value="Second Interview Requested">Second Interview Requested</option>
                  <option value="Second Interview Scheduled">Second Interview Scheduled</option>
                  <option value="Contract Offered">Contract Offered</option>
                  <option value="Interview Rejected">Interview Rejected</option>
                  <option value="Contract Not Offered">Contract Not Offered</option>
                </select>
              </div>
            </div>
            {applicants.length === 0 ? (
              <p className="text-gray-600 text-center">No applicants found for this job.</p>
            ) : (
              <div className="bg-white shadow-md rounded-lg">
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('users.name')} className="cursor-pointer">
                          Applicant {sortConfig.key === 'users.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('users.name')} className="cursor-pointer">
                          Applied {sortConfig.key === 'submitted_at' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('users.name')} className="cursor-pointer">
                          Consultant Rate {sortConfig.key === 'consultant_rate' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('users.name')} className="cursor-pointer">
                          Stage {sortConfig.key === 'status' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('users.name')} className="cursor-pointer">
                          Rating {sortConfig.key === 'users.average_rating' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3"></th>
                      </tr>
                  </thead>
                  <tbody className="bg-white text-left divide-y divide-gray-200">
                    {applicants.map((applicant) => (
                      <tr key={applicant.user_job_id} onClick={() => handleRowClick(applicant.user_job_id)} className="hover:bg-gray-50 cursor-pointer">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <input 
                              type="checkbox" 
                              onChange={() => toggleSelectApplicant(applicant.user_job_id)} 
                              className="mr-4" 
                              onClick={(e) => e.stopPropagation()}
                            />
                            <img src={applicant.users?.photo || '/default-avatar.png'} alt={applicant.users?.name} className="h-10 w-10 rounded-full mr-4" />
                            <div>
                              <p className="font-medium">{applicant.users?.name}</p>
                              <div className="flex items-center mt-1">
                                {applicant.users?.former_epic && (
                                  <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Ex-Epic</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <p>{new Date(applicant.submitted_at).toLocaleDateString()}</p>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <p>${applicant.consultant_rate}/hr</p>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-col items-start">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mb-1">
                              {applicant.status}
                            </span>
                            <div className="mt-1">
                              {renderProgressDots(applicant.status)}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-col items-start">
                            <div className="flex items-center">
                              {renderStars(applicant.users?.average_rating || 0)}
                            </div>
                            {applicant.users?.average_rating > 0 && (
                              <span className="mt-1 text-sm text-gray-500">
                                ({applicant.users?.review_count || 0} reviews)
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium relative">
                          <MoreHorizontal 
                            size={24} 
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown(applicant.user_job_id);
                            }}
                          />

                          {/* Dropdown menu */}
                          {activeDropdown === applicant.user_job_id && (
                            <div
                              ref={dropdownRef}
                              className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => window.open(applicant.users?.linkedin_url, '_blank')}
                              >
                                👤 LinkedIn Profile
                              </div>
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => navigate(`/app/client-interview-notes/${applicant.user_job_id}`)}
                              >
                                📝 See Notes
                              </div>
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => handleReject(applicant.user_job_id)}
                              >
                                ❌ Reject
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default Applicants;