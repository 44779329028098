import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientOfferContract = () => {
  const { userJobId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [applicant, setApplicant] = useState(null);

  useEffect(() => {
    fetchApplicantDetails();
  }, []);

  const fetchApplicantDetails = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          users!user_jobs_user_id_fkey (
            name,
            email
          ),
          job:job_id (
            title
          )
        `)
        .eq('user_job_id', userJobId)
        .single();

      if (error) throw error;
      setApplicant(data);
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!applicant) return <div>Applicant not found</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold mb-4">Offer Contract to {applicant.users.name}</h1>
      <p>For position: {applicant.job.title}</p>
      {/* Add form fields for contract details here */}
      <button
        onClick={() => {/* Handle contract offer submission */}}
        className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
      >
        Send Contract Offer
      </button>
    </div>
  );
};

export default ClientOfferContract;