import { getAnalytics, logEvent } from "firebase/analytics";
import { Calendar, Clock, DollarSign, Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";

const EngagementManagement = () => {
  const [engagements, setEngagements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Engagement Management',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserTypeAndEngagements();
  }, []);

  const fetchUserTypeAndEngagements = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('user_type_id')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      setUserType(userData.user_type_id);

      const isConsultant = userData.user_type_id === 2;
      const isClient = userData.user_type_id === 4;

      if (isConsultant || isClient) {
        await fetchEngagements(user.id, isConsultant);
      } else {
        throw new Error("Invalid user type");
      }
    } catch (error) {
      console.error('Error fetching user type and engagements:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEngagements = async (userId, isConsultant) => {
    try {
      let query = supabase
        .from('engagements')
        .select(`
          *,
          user_job:user_jobs(
            user_id,
            job:jobs(
              title,
              client:clients(name)
            ),
            consultant:users!user_jobs_user_id_fkey(name)
          )
        `)
        .in('status', ['Active', 'Accepted']);
        
      if (isConsultant) {
        query = query.eq('user_job.user_id', userId);
      } else {
        query = query.eq('user_job.job.created_by', userId);
      }

      const { data, error } = await query;

      if (error) throw error;
      setEngagements(data);
    } catch (error) {
      console.error('Error fetching engagements:', error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Helmet>
        <title>Engagement Management | fetchConsultant</title>
        <meta name="description" content="Manage your active engagements on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        {userType === 4 && (
          <ClientHamburger user={user} currentPage="engagements" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}
        {userType === 2 && (
          <ConsultantHamburger user={user} currentPage="engagements" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={toggleMenu} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Active Engagements</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="container mx-auto p-4">
            {isLoading ? (
              <p>Loading engagements...</p>
            ) : engagements.length === 0 ? (
              <p>No active engagements found.</p>
            ) : (
              <ul className="space-y-4">
                {engagements.map((engagement) => (
                  <li key={engagement.id} className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-2">{engagement.user_job.job.title}</h2>
                    <p className="mb-2">
                      {userType === 2 ? 'Client' : 'Consultant'}: {userType === 2 ? engagement.user_job.job.client.name : engagement.user_job.consultant.name}
                    </p>
                    <div className="flex items-center mb-2">
                      <Calendar className="mr-2" size={20} />
                      <span>Start Date: {new Date(engagement.created_at).toLocaleDateString()}</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <Clock className="mr-2" size={20} />
                      <span>Duration: {engagement.user_job.job.duration}</span>
                    </div>
                    <div className="flex items-center mb-4">
                      <DollarSign className="mr-2" size={20} />
                      <span>Rate: ${engagement.user_job.consultant_rate}/hr</span>
                    </div>
                    <button
                      onClick={() => navigate(`/app/engagement-details/${engagement.id}`)}
                      className="bg-brandPrimary text-white px-4 py-2 rounded hover:bg-brandHover"
                    >
                      View Details
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default EngagementManagement;