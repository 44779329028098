import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";
import NotificationBell from "./NotificationBell";
import ClientHamburger from './ClientHamburger';
import { Menu, Star } from 'lucide-react';

const ClientCreateInterviewNote = () => {
  const [note, setNote] = useState('');
  const [noteType, setNoteType] = useState('Interview');
  const [feedbackScore, setFeedbackScore] = useState(0);
  const [skills, setSkills] = useState('');
  const [strengths, setStrengths] = useState('');
  const [weaknesses, setWeaknesses] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [interviews, setInterviews] = useState([]);
  const [selectedInterviewId, setSelectedInterviewId] = useState('');
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultantId, setSelectedConsultantId] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const editNote = location.state?.note;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: editNote ? 'Edit Interview Note' : 'Create Interview Note',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchInterviews();
    fetchConsultants(); // Add this line

    if (editNote) {
      setNote(editNote.content);
      setNoteType(editNote.note_type);
      setFeedbackScore(editNote.feedback_score);
      setSkills(editNote.skills || '');
      setStrengths(editNote.strengths || '');
      setWeaknesses(editNote.weaknesses || '');
      setSelectedInterviewId(editNote.interview_id);
      setSelectedConsultantId(editNote.consultant_id);
    }
  }, [editNote]);

  useEffect(() => {
    if (location.state?.consultantId) {
      setSelectedConsultantId(location.state.consultantId);
    }
  }, [location]);

  // Add this new function outside of the useEffect
  const fetchConsultants = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('user_id, name')
        .eq('user_type_id', 2); // Assuming 2 is the consultant user type
      if (error) throw error;
      setConsultants(data);
    } catch (error) {
      console.error('Error fetching consultants:', error);
    }
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchInterviews = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('interviews')
        .select(`
          id,
          interview_time,
          user_job:user_jobs (
            job:jobs (title),
            consultant:users!user_jobs_user_id_fkey (user_id, name)
          )
        `)
        .eq('user_job.job.created_by', user.id)
        .order('interview_time', { ascending: false });
  
      if (error) throw error;
      
      // Filter out interviews without associated jobs
      const validInterviews = data.filter(interview => interview.user_job && interview.user_job.job);
      console.log('Fetched valid interviews:', validInterviews);
      setInterviews(validInterviews);
    } catch (error) {
      console.error('Error fetching interviews:', error.message);
    }
  };

const saveNote = async () => {
  setIsSaving(true);
  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    let consultantId = null;
    if (noteType === 'Interview' && selectedInterviewId) {
      const selectedInterview = interviews.find(i => i.id === selectedInterviewId);
      consultantId = selectedInterview?.user_job?.consultant?.user_id;
    }

    const noteData = {
      notewriter_id: user.id,
      content: note,
      note_type: noteType,
      feedback_score: feedbackScore,
      skills,
      strengths,
      weaknesses,
      interview_id: noteType === 'Interview' ? selectedInterviewId : null,
      consultant_id: selectedConsultantId,
      updated_at: new Date()
    };

    let result;
    if (editNote) {
      const { data, error } = await supabase
        .from('interview_notes')
        .update(noteData)
        .eq('id', editNote.id);
      result = { data, error };
    } else {
      noteData.created_at = new Date();
      const { data, error } = await supabase
        .from('interview_notes')
        .insert(noteData);
      result = { data, error };
    }

    if (result.error) throw result.error;
    navigate('/app/client-interview-notes');
  } catch (error) {
    console.error('Error saving note:', error);
  } finally {
    setIsSaving(false);
  }
};

  return (
    <>
      <Helmet>
        <title>{editNote ? 'Edit' : 'Create'} Interview Note | fetchConsultant</title>
        <meta name="description" content={`${editNote ? 'Edit an existing' : 'Create a new'} interview note for a candidate on fetchConsultant.`} />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="interviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">{editNote ? 'Edit' : 'Create'} Interview Note</h1>
            </div>
            <NotificationBell />
          </header>
          
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="bg-white shadow rounded-lg p-6">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Consultant</label>
              <select 
                value={selectedConsultantId}
                onChange={(e) => setSelectedConsultantId(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="">Select a consultant</option>
                {consultants.map((consultant) => (
                  <option key={consultant.user_id} value={consultant.user_id}>
                    {consultant.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Note Type</label>
                <select 
                  value={noteType}
                  onChange={(e) => {
                    setNoteType(e.target.value);
                    if (e.target.value !== 'Interview') {
                      setSelectedInterviewId('');
                    }
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="Interview">Interview</option>
                  <option value="General">General</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {noteType === 'Interview' && interviews.length > 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Associated Interview</label>
                  <select 
                    value={selectedInterviewId}
                    onChange={(e) => setSelectedInterviewId(e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">Select an interview</option>
                    {interviews.map((interview) => (
                      <option key={interview.id} value={interview.id}>
                        {interview.user_job.job.title} - {interview.user_job.consultant.name} ({new Date(interview.interview_time).toLocaleString()})
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Note Content</label>
                <ReactQuill
                  theme="snow"
                  value={note}
                  onChange={setNote}
                  className="h-64 mb-4"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Key Skills</label>
                <input
                  type="text"
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="e.g. Java, React, Project Management"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Strengths</label>
                <textarea
                  value={strengths}
                  onChange={(e) => setStrengths(e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  rows="3"
                  placeholder="Candidate's main strengths"
                ></textarea>
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">Areas for Improvement</label>
                <textarea
                  value={weaknesses}
                  onChange={(e) => setWeaknesses(e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  rows="3"
                  placeholder="Areas where the candidate could improve"
                ></textarea>
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">Overall Rating</label>
                <div className="flex items-center">
                  {[1, 2, 3, 4, 5].map((score) => (
                    <button
                      key={score}
                      onClick={() => setFeedbackScore(score)}
                      className="mr-2 focus:outline-none"
                    >
                      <Star
                        size={24}
                        className={`${
                          feedbackScore >= score ? 'text-yellow-400' : 'text-gray-300'
                        }`}
                        fill={feedbackScore >= score ? 'currentColor' : 'none'}
                      />
                    </button>
                  ))}
                </div>
              </div>

              <button
                onClick={saveNote}
                disabled={isSaving || !note.trim()}
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isSaving ? 'Saving...' : (editNote ? 'Update' : 'Save') + ' Note'}
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientCreateInterviewNote;